import { EvmCurrency } from '@/components/currency';
import { PageHeader } from '@/components/header';
import { Spinner } from '@/components/spinner';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { config } from '@/config';
import { useAddress } from '@/hooks/use_address';
import { useConnect, useDisconnect } from '@/hooks/use_connect';
import { useViewportHeight } from '@/hooks/use_viewport_height';
import { initData } from '@/lib/tg';
import { getCollection } from '@candysea/apis/candymint/v1/collection_service-CollectionService_connectquery';
import { Collection_Type } from '@candysea/apis/candymint/v1/message/collection_pb';
import { useSuspenseQuery } from '@connectrpc/connect-query';
import { useQuery } from '@tanstack/react-query';
import { Polygon } from 'cryptocons/src/components/Polygon';
import { Wallet } from 'lucide-react';
import { FC, lazy, Suspense } from 'react';
import { Address, createPublicClient, http } from 'viem';
import { getBalance } from 'viem/actions';

const CompositeItems = lazy(async () => {
  const mod = await import('@/components/templates/composite');
  return { default: mod.CompositeItems };
});

const DefaultItems = lazy(async () => {
  const mod = await import('@/components/templates/default');
  return { default: mod.DefaultItems };
});

export const App: FC = () => {
  const { data: collection } = useSuspenseQuery(getCollection, {
    collectionName: initData.appProps.collectionName,
    chainId: initData.appProps.chainId.toString(),
  });
  const { styles } = useViewportHeight();
  return (
    <div
      className="relative flex flex-col max-h-screen gap-4 overflow-y-auto"
      style={styles}
    >
      <PageHeader collection={collection} />
      <Tabs defaultValue="items" className="px-4">
        <TabsList className="w-full mb-2">
          <TabsTrigger value="items" className="flex-1">Items</TabsTrigger>
          <TabsTrigger value="info" className="flex-1">Info</TabsTrigger>
        </TabsList>
        <TabsContent value="items">
          <Suspense fallback={<Spinner loading />}>
            <>
              {(() => {
                switch (collection.type) {
                  case Collection_Type.PNG_COMPOSITE:
                  case Collection_Type.SVG_COMPOSITE:
                    return <CompositeItems collection={collection} />;
                  case Collection_Type.DEFAULT:
                    return <DefaultItems collection={collection} />;
                  default:
                    return <div>Not support</div>;
                }
              })()}
            </>
          </Suspense>
        </TabsContent>
        <TabsContent value="info">
          {collection.description}
        </TabsContent>
      </Tabs>
      <div className="py-3 text-xs text-center text-muted-foreground">Powered by CandyMint</div>
      <div className="sticky mx-auto bottom-4">
        <Suspense fallback={'Loading...'}>
          <AccountButton chainId={initData.appProps.chainId.toString()} />
        </Suspense>
      </div>
    </div>
  );
};

const AccountButton = ({ chainId }: { chainId: string }) => {
  const connect = useConnect();
  const disconnect = useDisconnect();
  const { address } = useAddress();
  return (
    <>
      {address
        ? <EvmAccountButton chainId={chainId} address={address} />
        : <ConnectButton />}
      <Button onClick={() => disconnect()}>Disconnect</Button>
    </>
  );
};

const ConnectButton = () => {
  const connect = useConnect();
  return (
    <Button
      onClick={() => connect.mutate()}
      disabled={connect.isPending}
      className="flex items-center gap-2 rounded-full bg-gradient-to-b from-[#FF39DF] to-[#FAA0A0]"
      size="lg"
    >
      <Spinner loading={connect.isPending}>
        <Wallet className="size-4" />
      </Spinner>
      Connect Wallet
    </Button>
  );
};

const EvmAccountButton = ({ address, chainId }: { address: Address; chainId: string }) => {
  const { data: balance } = useQuery({
    queryKey: ['balance', address],
    queryFn: async () => {
      const publicClient = createPublicClient({
        chain: config.supportedChains.find(chain => chain.id.toString() === chainId),
        transport: http(),
      });
      if (!address) return 0n;
      return await getBalance(publicClient, {
        address,
      });
    },
  });
  return (
    <Button className="flex items-center gap-2 rounded-full shadow-lg">
      <div className="flex items-center gap-1">
        <Polygon className="size-4" />
        <EvmCurrency value={balance ?? 0n} chainId={chainId} />
      </div>
      <Separator orientation="vertical" />
      {address?.slice(0, 6)}...{address?.slice(-4)}
    </Button>
  );
};
