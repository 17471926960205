import { mint, mintSepolia } from '@/lib/chains/mint';
import { initData } from '@/lib/tg';
import { EthereumBadge } from 'cryptocons/src/components/EthereumBadge';
import { MintBadge } from 'cryptocons/src/components/MintBadge';
import { PolygonBadge } from 'cryptocons/src/components/PolygonBadge';
import { Address, Chain, createPublicClient, http } from 'viem';
import { mainnet, polygon, polygonAmoy, sepolia } from 'viem/chains';

const IS_PROD = import.meta.env.VITE_VERCEL_ENV === 'production';
const DOMAIN = import.meta.env.VERCEL_PROJECT_PRODUCTION_URL
  ? import.meta.env.VERCEL_PROJECT_PRODUCTION_URL
  : IS_PROD
  ? 'miniapp.candymint.io'
  : 'miniapp-testnet.candymint.io';

export const iconsByChainId: Record<number, React.FC<any>> = {
  [polygon.id]: PolygonBadge,
  [polygonAmoy.id]: PolygonBadge,
  [mainnet.id]: EthereumBadge,
  [sepolia.id]: EthereumBadge,
  [mint.id]: MintBadge,
  [mintSepolia.id]: MintBadge,
};

export const config = {
  isProd: IS_PROD,
  baseURL: `https://${DOMAIN}`,
  // Chains that will show in the connect modal, order matters. All chains
  // configured here must have a slug in `chainSlugs` below.
  supportedChains: [polygon, polygonAmoy] as const,
  chainSlugs: {
    [polygon.id.toString()]: 'polygon',
    [polygonAmoy.id.toString()]: 'amoy',
    [sepolia.id.toString()]: 'sepolia',
    [mint.id.toString()]: 'mint',
    [mintSepolia.id.toString()]: 'mint-sepolia',
  },
  contracts: {
    [polygon.id.toString()]: {
      entry: '0xCF345b0e73f68B449e1a4Ff2533550AAf82d1B83',
      view: '0x3808bd69d8943B9b1eb8e4Ced2A0977C36346d66',
    },
    [polygonAmoy.id.toString()]: {
      entry: '0x12a6d1c84207D551BD36c809643a119971901a7d',
      view: '0x3B99aCC2BCdC086C966B8be28c505ee4Afd136f7',
    },
    [sepolia.id.toString()]: {
      entry: '0xF0BF708207a61b557080f72b6A04191EF603C5aD',
      view: '0xF84F7EE42cBebfe7795b9De4a0DD85d54E21b262',
    },
    [mint.id.toString()]: {
      entry: '0xCF345b0e73f68B449e1a4Ff2533550AAf82d1B83',
      view: '0xBf810833D48A63Aee1a5172A31F2F82fBFDfd1Fb',
    },
    [mintSepolia.id.toString()]: {
      entry: '0x646704b7FF6eC35287160DBe82FCc8978a2b7178',
      view: '0x9F33468d8EE846d0629F2A6F5acACc1d4165160F',
    },
  } satisfies { [key: string]: { entry: Address; view: Address } },
  api: {
    gateway: import.meta.env.VITE_GATEWAY_ENDPOINT || IS_PROD
      ? 'https://gateway.candysea.io'
      : 'https://gateway-testnet.candysea.io',
  },
  joyId: {
    appName: 'CandyMint',
    logo: 'https://fav.farm/🆔',
    appUrl: import.meta.env.JOYID_APP_URL || IS_PROD ? 'https://app.joy.id' : 'https://testnet.joyid.dev',
    callbackServer: `https://${DOMAIN}`,
  },
} as const;

export const publicClient = createPublicClient({
  chain: config.supportedChains.find(chain => chain.id.toString() === initData.appProps.chainId.toString()),
  transport: http(),
});
