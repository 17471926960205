import twa from '@twa-dev/sdk';

/**
 * init data parsed from twa.initData
 *
 * @readonly
 */
export const initData = parseInitData(twa.initData);

export interface InitData {
  user: string;
  parsedUser: {
    allows_write_to_pm: true;
    first_name: string;
    id: number;
    language_code: string;
    last_name: string;
    username: string;
  };
  start_param: string;
  appProps: AppProps;
}

export interface AppProps {
  chainId: number;
  collectionName: string;
}

export function parseInitData(initData: string): Readonly<InitData> {
  const data: InitData = decodeURIComponent(initData).split('&').reduce((acc, cur) => {
    const [key, value] = cur.split('=');
    acc[key] = value;
    return acc;
  }, {} as any);
  if (data.user) {
    data.parsedUser = JSON.parse(data.user);
  }
  const appPropsFromLocation = getStartPropsFromLocation();
  const appPropsFromStartParam = getStartPropsFromStartParams(data.start_param);
  const appProps = appPropsFromLocation || appPropsFromStartParam;
  if (!appProps) {
    throw new Error('chainId and collection name must be provided');
  }
  data.appProps = appProps;
  return data;
}

export function getStartPropsFromLocation(): AppProps | undefined {
  const pathname = window.location.pathname;
  const result = /^\/collections\/(?<chainId>\d+)\/(?<collectionName>\w+)$/.exec(pathname);
  return result?.groups as AppProps | undefined;
}

export function getStartPropsFromStartParams(startParam: string): AppProps | undefined {
  const result = /^(?<chainId>\d+)__(?<collectionName>\w+)$/.exec(startParam);
  return result?.groups as AppProps | undefined;
}
