import { joyIdClient } from '@/api';
import { useAddress } from '@/hooks/use_address';
import { buildConnectUrlFromInitData } from '@/lib/joy_id';
import { initData } from '@/lib/tg';
import { retry } from '@/lib/utils';
import { useMutation } from '@tanstack/react-query';
import twa from '@twa-dev/sdk';
import { useCallback } from 'react';
import { Address } from 'viem';

export const useConnect = () => {
  const { setAddress } = useAddress();
  return useMutation({
    mutationFn: async () => {
      const { url, token } = buildConnectUrlFromInitData(initData);
      twa.openLink(url);
      return retry(async () => {
        const { address } = await joyIdClient.url('/messages').post({ token }).json<{ address: Address }>();
        return address;
      }, 240);
    },
    onSuccess: address => {
      setAddress(address);
    },
  });
};

export const useDisconnect = () => {
  const { setAddress } = useAddress();
  return useCallback(() => {
    setAddress(undefined);
  }, [setAddress]);
};
