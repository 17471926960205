import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function retry<T>(fn: () => Promise<T>, maxAttempts: number, delay = 500) {
  return new Promise<any>(async (resolve, reject) => {
    let attempts = 0;
    while (attempts < maxAttempts) {
      try {
        const result = await fn();
        resolve(result);
      } catch (error) {
        attempts++;
        if (attempts < maxAttempts) {
          await sleep(delay);
        } else {
          reject(error);
        }
      }
    }
  });
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
