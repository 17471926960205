import twa from '@twa-dev/sdk';
import { atom, useAtom } from 'jotai';
import { Address } from 'viem';

const addressAtom = atom<Address | undefined>(undefined);

const asyncAddressAtom = atom(
  async (get) => {
    const address = get(addressAtom);
    if (address) {
      return address;
    }
    return getAddressFromCloudStorage();
  },
  (get, set, address: Address | undefined) => {
    set(addressAtom, address);
    try {
      if (address) {
        saveAddressToCloudStorage(address);
      } else {
        removeAddressFromCloudStorage();
      }
    } catch (err) {
      console.error(err);
    }
  },
);

export const useAddress = () => {
  const [address, setAddress] = useAtom(asyncAddressAtom);
  return {
    address,
    setAddress,
  };
};

function getAddressFromCloudStorage() {
  return new Promise<Address | undefined>((resolve) => {
    try {
      twa.CloudStorage.getItem(`address`, (err, result) => {
        if (!err) {
          // If there is no address in the cloud storage, it means the user has not connected to the wallet yet.
          console.debug(`failed to recover address from cloud storage: ${err}`);
        }
        resolve(result as Address | undefined);
      });
    } catch (err) {
      console.error(`failed to recover address from cloud storage: ${err}`);
      resolve(undefined);
    }
  });
}

function saveAddressToCloudStorage(address: Address) {
  return new Promise<void>((resolve) => {
    try {
      twa.CloudStorage.setItem(`address`, address, (err, result) => {
        if (err) {
          console.error(`failed to save address to cloud storage: ${err}`);
        }
        if (!result) {
          console.error(`failed to save address to cloud storage: unknown error`);
        }
        resolve();
      });
    } catch (err) {
      console.error(`failed to save address to cloud storage: ${err}`);
      resolve();
    }
  });
}

function removeAddressFromCloudStorage() {
  return new Promise<void>((resolve) => {
    try {
      twa.CloudStorage.removeItem(`address`, (err, result) => {
        if (err) {
          console.error(`failed to remove address from cloud storage: ${err}`);
        }
        if (!result) {
          console.error(`failed to remove address from cloud storage: unknown error`);
        }
        resolve();
      });
    } catch (err) {
      console.error(`failed to remove address from cloud storage: ${err}`);
      resolve();
    }
  });
}
