import { publicTransport } from '@/api';
import { App } from '@/app';
import { PageHeaderSkeleton } from '@/components/header';
import { ErrorBoundary } from '@/error';
import { TransportProvider } from '@connectrpc/connect-query';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import twa from '@twa-dev/sdk';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'sonner';
import './globals.css';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://ac17dde9e96f7cff21da106124832b02@o4506264861999104.ingest.us.sentry.io/4507809222754304',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <TransportProvider transport={publicTransport}>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Suspense fallback={<PageHeaderSkeleton />}>
          <App />
        </Suspense>
      </ErrorBoundary>
      <Toaster />
    </QueryClientProvider>
  </TransportProvider>,
);

window.twa = twa;
